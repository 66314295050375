import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { getFromStorage } from '../util/storage-utils';
import colors from '../themes/main-colors';

import BreadCrumb from '../components/pge-breadcrumb';
import {
  renderPageComponents,
  getHeroComponent,
  getSplitHeaderComponent,
  getStatusAlertComponent,
} from '../util/contentful-render-utils';
import './css/contentful-pages.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: colors.white,
      width: '100%',
      '& .ContentfulElementColumns2 .spacingComponent': {
        padding: 0,
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(1.25, 0),
        },
      },
      '& .ContentfulElementColumns3 .spacingComponent': {
        padding: 0,
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(1.25, 0),
        },
      },
    },
    header: {
      '& .hero-container': {
        minHeight: 320,
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(6.25, 5),
          height: 'auto',
          minHeight: 220,
        },
      },
      '& .hero-container h1': {
        fontSize: theme.spacing(4.5),
        lineHeight: theme.typography.pxToRem(40),
        fontWeight: 100,
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.spacing(3),
          lineHeight: theme.typography.pxToRem(28),
          fontWeight: 700,
        },
      },
      '& .hero-container h1.heading-two': {
        maxWidth: theme.typography.pxToRem(450),
      },
      '& .hero-container p': {
        fontSize: theme.spacing(2.5),
        lineHeight: theme.typography.pxToRem(30),
        maxWidth: theme.typography.pxToRem(450),
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.typography.pxToRem(16),
        },
      },
      '& .hero-container .button > .MuiButton-root': {
        [theme.breakpoints.down('sm')]: {
          backgroundColor: 'transparent !important',
          border: '2px solid #fff !important',
          color: colors.white,
        },
      },
    },
    menu: {
      backgroundColor: colors.white,
      padding: theme.spacing(2.5, 7.5, 0, 0),
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    heading: {
      fontSize: theme.spacing(4.5),
      color: colors.noirBlur,
      lineHeight: theme.typography.pxToRem(40),
      padding: theme.spacing(0, 0, 2.5, 0),
      fontWeight: 100,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        lineHeight: theme.typography.pxToRem(28),
        padding: theme.spacing(0, 0, 1.25, 0),
      },
    },
    description: {
      fontSize: theme.spacing(3),
      color: colors.noirBlur,
      lineHeight: theme.typography.pxToRem(28),
      marginBottom: theme.spacing(2.5),
      fontWeight: 100,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2.5),
        lineHeight: theme.typography.pxToRem(24),
        marginBottom: theme.spacing(1.25),
      },
    },
    image: {
      width: '100%',
      height: 275,
      borderRadius: 5,
      marginBottom: theme.spacing(2.5),
      objectFit: 'cover',
    },
  }),
);

export const BasicTemplate = ({ pageContext, data }: any) => {
  const classes = useStyles();
  const locale = getFromStorage('locale');
  const page = pageContext[locale || 'en'] || pageContext['es'];
  const basicPageData = data?.allContentfulPageBasic?.nodes[0];

  const contentBodyHeaderType =
    basicPageData?.contentEntries &&
    basicPageData?.contentEntries[0]?.__typename;
  let header;
  if (
    contentBodyHeaderType === 'ContentfulHeaderSplit' ||
    contentBodyHeaderType === 'ContentfulHeaderhero'
  ) {
    header =
      contentBodyHeaderType === 'ContentfulHeaderhero'
        ? getHeroComponent([basicPageData?.contentEntries[0]])
        : getSplitHeaderComponent(basicPageData?.contentEntries[0]);
  }
  return (
    <>
      <Helmet bodyAttributes={{ class: 'TemplatePage' }}>
        <title>{basicPageData?.browserTitle || basicPageData?.pageTitle}</title>
        {basicPageData?.metaDescriptionSeo && (
          <meta name="description" content={basicPageData.metaDescriptionSeo} />
        )}
        {basicPageData?.excludeFromGoogleSearch && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      {header && <div className={classes.header}>{header}</div>}
      <Container className="component-container">
        <div className={classes.root}>
          <BreadCrumb path={page?.breadcrumb} />
          {getStatusAlertComponent(basicPageData?.statusAlert)}

          {!header && (
            <div>
              {basicPageData?.pageTitle && (
                <div className={classes.heading}>
                  {basicPageData?.pageTitle}
                </div>
              )}
              {basicPageData?.pageSubtitle && (
                <div className={classes.description}>
                  {basicPageData?.pageSubtitle}
                </div>
              )}
              {basicPageData?.image?.file?.url && (
                <img
                  className={classes.image}
                  src={basicPageData?.image?.file?.url}
                />
              )}
            </div>
          )}
          {renderPageComponents({
            entriesData: basicPageData?.contentEntries || null,
          })}
        </div>
      </Container>
    </>
  );
};

export default BasicTemplate;

export const query = graphql`
  query PageBasic($slug: String!) {
    allContentfulPageBasic(filter: { slug: { eq: $slug } }) {
      nodes {
        node_locale
        contentful_id
        entryName
        shortMenuTitle
        slug
        pageTitle
        pageSubtitle
        pageShortDescription
        browserTitle
        metaDescriptionSeo
        image {
          file {
            contentType
            fileName
            url
          }
        }
        contentEntries {
          ...ElementJumpLinks
          ...ModuleContentBody
          ...ModuleColumn3
          ...ModuleContact
          ...ModuleCallOut
          ...CTACard
          ...ModuleCallToAction
          ...ModuleMessagingBlock
          ...ModuleHeroHeader
          ...ModuleSplitHeader
          ...ModuleAccordion
          ...GallerySlider
          ...ModulePromoGroup
          ...ModuleRelated
          ...StaticList
          ...ModulePullQuote
          ...ModuleColumn2
          ...ModuleEmbedMediaWrapper
          ...ModuleFormWrapper
          ...ModuleImageWrapper
          ...ModuleTabSet
          ...ModuleStatusAlert
        }
        statusAlert {
          ...ModuleStatusAlert
        }
      }
    }
  }
`;
